<template>
    <div class="group">
        <div class="content">
             <div class="tree">
                 <p class="company">公司组织架构</p>
                 <a-tree v-show="tabsActive==0" :treeData="treeData" @select="_treeSelect"/>
             </div>
             <div class="list">
                 <div style="clear: both"></div>
                 <div class="search" style="display: block">

                    <a-select default-value="all" style="width: 120px" @change="_handleChange">
                        <a-select-option value="all">全部</a-select-option>
                        <a-select-option value="1">每日辅导</a-select-option>
                        <a-select-option value="2">每日视频</a-select-option>
                        <a-select-option value="3">每日练习</a-select-option>
                        <a-select-option value="4">每日故事</a-select-option>
                        <a-select-option value="5">每日听听</a-select-option>
                    </a-select>

                     <a-range-picker  @change="_onChange" :default-value="[moment(StartDate, dateFormat), moment(EndDate, dateFormat)]" :format="dateFormat"/>
                     <a-button class="search-style" 重置</a-button>
                     <a-button type="primary" style="margin-left: 10px;" @click="_search">查询</a-button>
                 </div>
                 <div>
                    <a-table :columns="columns" :dataSource="StatData" :pagination='false' style="margin-top:10px;border:1px solid #efefef">
                        <div slot="user_count"  slot-scope="text, record, index"  >
                            <a @click="_showUserList(record,index,'all','all')" style="color: #1890ff;">{{text}}</a>
                        </div>
                        <div slot="OpeningedCount"  slot-scope="text, record, index"  >
                            <a @click="_showUserList(record,index,1,'all')" style="color: #1890ff;">{{text}}</a>
                        </div>
                        <div slot="UseOmoUserCount"  slot-scope="text, record, index"  >
                            <a @click="_showUserList(record,index,'all',1)" style="color: #1890ff;">{{text}}</a>
                        </div>
                        <div slot="peopleOneDayUseDuration"  slot-scope="text, record, index" >
                            {{Math.floor(text * 100)/100}}
                        </div>
                        <div slot="peopleOneDayStartUpCount"  slot-scope="text, record, index" >
                            {{Math.floor(text * 100)/100}}
                        </div>
                    </a-table> 
                 </div>
            </div>

        
    </div>
    <a-drawer
        :title="showUserListName+'客户列表'"
        width="1000"
        :closable="false"
        :visible="visible"
        @close="onClose"
        :destroyOnClose="true"
    >
    <userlist :queryData="queryData"></userlist>
    </a-drawer>

    
</div>
</template>

<script>
    import moment from 'moment';
    import userlist from 'pages/Cloudcoach/user_list';
    
    export default {
        name: "CloudcoachReportNew",
        components:{userlist},
        data(){
            return{
                userinfo:JSON.parse(sessionStorage.getItem("userInfo")),
                tabsActive:0,
                selectOrgId:'',
                selectOrgName:'',
                StartDate:'',
                EndDate:'',
                treeData:[],
                columns:[
                    { title: '序号', width: 100, dataIndex: 'sNumber', fixed: 'left' },
                    { title: '机构名称', dataIndex: 'Name', key: '2' },
                    { title: '总客户数', dataIndex: 'user_count', key: '4' ,scopedSlots: { customRender: 'user_count' }},
                    { title: '可使用人数', dataIndex: 'OpeningedCount', key: '5',scopedSlots: { customRender: 'OpeningedCount' } },
                    { title: '实际使用人数', dataIndex: 'UseOmoUserCount', key: '6' ,scopedSlots: { customRender: 'UseOmoUserCount' }},
                    { title: '人均单日使用时长(分)', dataIndex: 'peopleOneDayUseDuration', key: '7' ,scopedSlots: { customRender: 'peopleOneDayUseDuration' }},
                    { title: '今日使用人数', dataIndex: 'todayUseCount', key: '8' },
                    { title: '人均单日启动次数', dataIndex: 'peopleOneDayStartUpCount', key: '9' ,scopedSlots: { customRender: 'peopleOneDayStartUpCount' }},
                  ],
                StatData:[],
                SummaryData:[],
                dateFormat:"YYYY-MM-DD",
                Type:'all',
                PartLevel:'',
                visible:false,
                queryData:{},
                showUserListName:'',
                
            }
        },
        methods:{
            moment
        },
        created(){
            this.StartDate = moment().startOf('week').format(this.dateFormat);
            this.EndDate = moment(new Date()).format(this.dateFormat);
            this._getOrgList();
            this._getStatData();
        },
        mounted(){
            
        },
        computed: {


        },
        methods:{
            moment,
            _tabsActive(i){
                this.tabsActive = i;
            },
            _getOrgList(){
                this.$axios.get(7000012,{
                    UserId:this.userinfo.uid,
                },res=>{
                    this.treeData = res.data;
                })
            },
            _getStatData(){
                this.$message.loading('数据加载中...',0);
                this.$axios.get(7000010,{
                    OrgId:this.selectOrgId,
                    OrgName:this.selectOrgName,
                    PartLevel:this.PartLevel,
                    StartDate:this.StartDate,
                    EndDate:this.EndDate,
                    Type:this.Type,
                },res=>{
                    if (res.data.code == 1) {
                        this.StatData = res.data.data;
                    } else {
                        this.StatData = [];
                    }
                    this.$message.destroy();
                })
            },
            _treeSelect(key,e){
                this.selectOrgId = e.node.dataRef.value;
                this.selectOrgName = e.node.dataRef.title;
                this.PartLevel = e.node.dataRef.PartLevel;
                this._getStatData();
            },
            _onChange(v,dateString){
                this.StartDate = dateString[0];
                this.EndDate = dateString[1];
            },
            _search(){
                this._getStatData();
            },
            _rest(){
                this.StartDate = '';
                this.EndDate = '';
            },
            _handleChange(value){
                this.Type = value;
            },
            onClose() {
                this.visible = false;
            },
            _showUserList(record,index,isOpening,isExperience){
                this.showUserListName = record.Name;
                this.visible = true;
                this.queryData = {
                    Id:record.Id,
                    Type:record.objectType,
                    StartDate:this.StartDate,
                    EndDate:this.EndDate,
                    CloudType:this.Type,
                    isOpening:isOpening,
                    isExperience:isExperience
                };
            },
            
        }
    }
</script>

<style lang="less" scoped>
    *{
        font-family:Microsoft YaHei;
    }
    .tabs{
        border-bottom: 1px solid #CCD1DC;
        border-left: 1px solid #CCD1DC;
        border-top-left-radius: 5px;

        ul{
            li{
                display: inline-block;
                width: 85px;
                padding: 6px 0;
                text-align: center;
                border: 1px solid #CCD1DC;
                border-bottom: none;
                cursor: pointer;
                border-left: 0;
                &:first-child{
                    border-top-left-radius: 5px;
                }
                &:last-child{
                    border-top-left-radius: 0;
                    border-top-right-radius: 5px;
                }
            }
            .active{
                background: #1890FF;
                color: #fff;
                border: 1px solid #1890FF;
            }
        }
    }
    .content{
        margin-top: 15px;
        display: flex;
        min-height: 800px;
        .tree{
            width: 200px;
            background: #ffffff;
            padding: 15px;
            border: 1px solid #DFE0E6;
            border-radius: 5px;
            .company{
                color: #242424;
                font-size:14px;
            }
        }
        .list{
            padding: 15px;
            background: #ffffff;
            margin-left: 20px;
            flex: 1;
            padding-right: 75px;
            .list-title{
                /*width: 400px;*/
                li{
                    width: 15.8%;
                    margin-right: 1%;
                    float: left;
                    border:1px solid rgba(224,234,244,1);
                    text-align: center;
                    border-radius: 5px;
                    padding: 10px 0px;
                    cursor: pointer;
                    span{
                        display: block;
                        font-size:14px;
                        color:rgba(153,153,153,1);
                    }
                    .typeNum{
                        color:rgba(102,102,102,1);
                        font-size: 16px;
                    }
                }
                .title-active{
                    border:1px solid rgba(41,148,255,1);
                }
                li:last-child{
                    margin-right: 0;
                }
            }

        }
    }
    .search{
        display: flex;
        margin-top: 20px;
        margin-bottom: 25px;
        .search-style{
            margin-right: 10px;
        }
    }
    .hook{
        color:rgba(41,148,255,1);
        cursor: pointer;
    }
</style>