<template>
    <div class="group">
        <div class="content">
             <div class="list">
                 <div style="clear: both"></div>
                 <div class="search" style="display: block">

                    <a-select  placeholder="是否可使用" style="width: 120px" @change="_onStateChange(1,$event)">
                        <a-select-option value="all">全部</a-select-option>
                        <a-select-option value="1">是</a-select-option>
                        <a-select-option value="0">否</a-select-option>
                    </a-select>

                    <a-select  placeholder="是否已使用" style="width: 120px" @change="_onStateChange(2,$event)">
                        <a-select-option value="all">全部</a-select-option>
                        <a-select-option value="1">是</a-select-option>
                        <a-select-option value="0">否</a-select-option>
                    </a-select>


                    <a-select  placeholder="今日是否使用" style="width: 120px" @change="_onStateChange(3,$event)">
                        <a-select-option value="all">全部</a-select-option>
                        <a-select-option value="1">是</a-select-option>
                        <a-select-option value="0">否</a-select-option>
                    </a-select>

                     <a-range-picker  @change="_onChange" :default-value="[moment(StartDate, dateFormat), moment(EndDate, dateFormat)]" :format="dateFormat"/>
                     <!-- <a-button class="search-style" @click="_rest">重置</a-button> -->
                     <a-button type="primary" style="margin-left: 10px;" @click="_search">查询</a-button>
                 </div>
                 <div>
                    <a-table :columns="columns" :dataSource="StatData" :pagination='false' style="margin-top:10px;border:1px solid #efefef">
                        <div slot="StudentName"  slot-scope="text, record, index"  >
                            <a @click="_showUserLoggs(record)" style="color: #1890ff;">{{text}}</a>
                        </div>
                    </a-table>
                    <div class="pagination" v-if="tableDataCount > 10" style="text-align:right">
                        <a-pagination :total="tableDataCount" :current="currPage" style="display: inline-block;margin-top:20px;" @change="_tablePage" />
                    </div>
                 </div>
            </div>

        
    </div>

    <a-drawer
        :title="showUserLoggsName+'云辅导使用情况'"
        width="80%"
        :closable="false"
        :visible="LogsCalendarState"
        @close="onLogsCalendarClose"
        :destroyOnClose="true"
    >
    <LogsCalendar :LogsCalendarQueryData="LogsCalendarQueryData"></LogsCalendar>
    </a-drawer>
</div>
</template>

<script>
    import moment from 'moment';
    import LogsCalendar from 'pages/Cloudcoach/LogsCalendar';
    export default {
        name: "user_list",
        components:{LogsCalendar},
        data(){
            return{
                userinfo:JSON.parse(sessionStorage.getItem("userInfo")),
                tabsActive:0,
                selectOrgId:'',
                selectOrgName:'',
                StartDate:'',
                EndDate:'',
                treeData:[],
                columns:[
                    { title: '序号', dataIndex: 'sNumber' },
                    { title: '客户名称', dataIndex: 'StudentName', key: '2',scopedSlots: { customRender: 'StudentName' }},
                    { title: '是否可使用', dataIndex: 'isOpening', key: '4' },
                    { title: '是否已使用', dataIndex: 'isExperience', key: '5' ,},
                    { title: '今日是否使用', dataIndex: 'isTodayExperience', key: '6' },
                    { title: '已使用次数', dataIndex: 'experience_count', key: '7' },
                    { title: '累计时长(分)', dataIndex: 'duration', key: '8' },
                    { title: '单日平均使用时长(分)', dataIndex: 'aveDayDuration', key: '9' },
                    { title: '单日启动次数', dataIndex: 'oneDayAveStartupCount', key: '10' },
                  ],
                StatData:[],
                SummaryData:[],
                dateFormat:"YYYY-MM-DD",
                Type:'',
                Id:'',
                PartLevel:'',
                isOpening:'all',
                isExperience:'all',
                isTodayExperience:'all',
                tableDataCount:0,
                currPage:1,
                CloudType:'all',
                showUserLoggsName:'',
                LogsCalendarQueryData:{},
                LogsCalendarState:false,
            }
        },
        props:['queryData'],
        watch:{
            queryData:{
                immediate:true,
                handler(val){
                    this.Id = val.Id;
                    this.Type = val.Type;
                    this.currPage = 1;
                    this.tableDataCount = 0;
                    this.StatData = [];
                    this.isOpening = val.isOpening;
                    this.isExperience = val.isExperience;
                    this.isTodayExperience = 'all';
                    this.StartDate = val.StartDate;
                    this.EndDate = val.EndDate;
                    this.CloudType = val.CloudType
                    this._getStatData();
                },
                deep: true,
            }
        },
        methods:{
            moment
        },
        created(){
            // this.StartDate = moment().startOf('month').format(this.dateFormat);
            // this.EndDate = moment(new Date()).format(this.dateFormat);
            // this._getOrgList();
            // this._getStatData();

        },
        mounted(){
            
        },
        computed: {


        },
        methods:{
            moment,
            _tabsActive(i){
                this.tabsActive = i;
            },
            _getOrgList(){
                this.$axios.get(1090,{
                    UserId:this.userinfo.uid,
                },res=>{
                    this.treeData = res.data;
                })
            },
            _getStatData(){
                this.$message.loading('数据加载中...',0);
                this.$axios.get(7000011,{
                    Id:this.Id,
                    Type:this.Type,
                    isOpening:this.isOpening,
                    isExperience:this.isExperience,
                    isTodayExperience:this.isTodayExperience,
                    StartDate:this.StartDate,
                    EndDate:this.EndDate,
                    page:this.currPage,
                    CloudType:this.CloudType
                },res=>{
                    if (res.data.code == 1) {
                        this.StatData = res.data.data;
                        this.tableDataCount = res.data.count
                    } else {
                        this.StatData = [];
                        this.tableDataCount = 0;
                    }
                    this.$message.destroy();
                })
            },
            _onChange(v,dateString){
                this.StartDate = dateString[0];
                this.EndDate = dateString[1];
            },
            _search(){
                this.currPage = 1;
                this._getStatData();
            },
            _rest(){
                this.StartDate = '';
                this.EndDate = '';
                this.currPage = 1;
                this.isOpening = 'all';
                this.isExperience = 'all';
                this.isTodayExperience ='all';
            },
            _handleChange(value){
                this.Type = value;
            },
            _tablePage(page){
                this.currPage = page;
                this._getStatData();
            },
            _onStateChange(type,e){
                switch(type) {
                    case 1:
                        this.isOpening = e;
                        break;
                    case 2:
                        this.isExperience = e;
                        break;
                    case 3:
                        this.isTodayExperience = e;
                        break;
                    default:
                }
                this.currPage = 1;
            },
            _showUserLoggs(record){
                this.LogsCalendarState = true;
                this.showUserLoggsName = record.StudentName;
                this.LogsCalendarQueryData = {
                    StudentId:record.StudentId,
                    StartDate:this.StartDate,
                    EndDate:this.EndDate,
                };
            },
            onLogsCalendarClose(){
                this.showUserLoggsName = '';
                this.LogsCalendarQueryData = {};
                this.LogsCalendarState = false;
            }
        }
    }
</script>

<style lang="less" scoped>
    *{
        font-family:Microsoft YaHei;
    }
    .tabs{
        border-bottom: 1px solid #CCD1DC;
        border-left: 1px solid #CCD1DC;
        border-top-left-radius: 5px;

        ul{
            li{
                display: inline-block;
                width: 85px;
                padding: 6px 0;
                text-align: center;
                border: 1px solid #CCD1DC;
                border-bottom: none;
                cursor: pointer;
                border-left: 0;
                &:first-child{
                    border-top-left-radius: 5px;
                }
                &:last-child{
                    border-top-left-radius: 0;
                    border-top-right-radius: 5px;
                }
            }
            .active{
                background: #1890FF;
                color: #fff;
                border: 1px solid #1890FF;
            }
        }
    }
    .content{
        margin-top: 15px;
        display: flex;
        min-height: 800px;
        .tree{
            width: 200px;
            background: #ffffff;
            padding: 15px;
            border: 1px solid #DFE0E6;
            border-radius: 5px;
            .company{
                color: #242424;
                font-size:14px;
            }
        }
        .list{
            /*padding: 15px;*/
            background: #ffffff;
            /*margin-left: 20px;*/
            flex: 1;
            /*padding-right: 75px;*/
            .list-title{
                /*width: 400px;*/
                li{
                    width: 15.8%;
                    margin-right: 1%;
                    float: left;
                    border:1px solid rgba(224,234,244,1);
                    text-align: center;
                    border-radius: 5px;
                    padding: 10px 0px;
                    cursor: pointer;
                    span{
                        display: block;
                        font-size:14px;
                        color:rgba(153,153,153,1);
                    }
                    .typeNum{
                        color:rgba(102,102,102,1);
                        font-size: 16px;
                    }
                }
                .title-active{
                    border:1px solid rgba(41,148,255,1);
                }
                li:last-child{
                    margin-right: 0;
                }
            }

        }
    }
    .search{
        display: flex;
        margin-top: 20px;
        margin-bottom: 25px;
        .search-style{
            margin-right: 10px;
        }
    }
    .hook{
        color:rgba(41,148,255,1);
        cursor: pointer;
    }
</style>