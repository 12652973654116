<template>
    <div class="LogsCalendar">
        <div class="title flex">
            <div>
                <!-- <label class="record-title">客户跟踪概况</label> -->
            </div>
            <div>
                

                <span class="record-tips tips-error">每日听听</span>
                <span class="record-tips tips-success">每日视频</span>
                <span class="record-tips tips-warning">每日故事</span>                
                <span class="record-tips tips-error2">每日练习</span>
                <span class="record-tips tips-error3">每日辅导</span>

                <a-select style="width: 200px;margin-right: 20px;" placeholder="下拉筛选项目" @change="_handleChange">
                    <a-select-option value="">全部</a-select-option>
                    <a-select-option value="1">每日辅导</a-select-option>
                    <a-select-option value="2">每日视频</a-select-option>
                    <a-select-option value="3">每日练习</a-select-option>
                    <a-select-option value="4">每日故事</a-select-option>
                    <a-select-option value="5">每日听听</a-select-option>
                </a-select>

                <a-range-picker style="margin-right: 10px;width:200px" :value="[moment(startDate, dateFormat), moment(endDate, dateFormat)]" :format="dateFormat" @change="_getDate"/>

                <!-- <a-button @click="totay" style="margin-right: 50px">totay</a-button> -->
            </div>
        </div>
        <a-calendar @panelChange="onPanelChange" :value="moment(dateValue)" :header-render="headerRender">
            <ul class="events" slot="dateCellRender" slot-scope="value">
                <li v-for="item in getListData(value)" :key="item.content" :style="{background:item.bg_color}">
                    <span>{{item.time}}({{item.dn}}分钟)</span>
                </li>
            </ul>
            <!-- <template slot="monthCellRender" slot-scope="value"> -->
                <!-- <div
                        v-for="item in getMonthData(value)"
                        :key="item.content"
                        class="notes-month"
                > -->
                <!-- <span>8.30-9.30(60分钟) 每日辅导2</span>
                    <span>8.30-9.30(60分钟) 每日练习2</span>
                    <span>8.30-9.30(60分钟) 每日视频2</span>
                    <span>8.30-9.30(60分钟) 每日听听2</span> -->
                <!-- </div> -->
            <!-- </template> -->
        </a-calendar>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'LogsCalendar',
        data() {
            return {
                dateValue: '',
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                TheTime: '',
                Name: '',
                visible: false,
                nowTime: '',
                list: '',
                successCount: '',
                errorCount: '',
                Count: '',
                columns: [
                    {
                        title: '每日听听',
                        key: 'Status',
                        dataIndex: 'Status',
                        width: 92,
                        scopedSlots: {customRender: 'Status'}
                    },
                    {
                        title: '每日视频',
                        key: 'StudentName',
                        dataIndex: 'StudentName',
                        width: 92,
                        scopedSlots: {customRender: 'StudentName'}
                    },
                    {
                        title: '每日故事',
                        key: 'StudentStatus',
                        dataIndex: 'StudentStatus',
                        width: 92
                    },
                    {
                        title: '每日练习',
                        key: 'TrecordStatusName',
                        dataIndex: 'TrecordStatusName',
                        width: 92
                    },
                    {title: '每日辅导', key: 'RecordTit', dataIndex: 'RecordTit'},
                ],
                Popupdata: '',
                tableUserid: '',
                tableTime: '',
                TableStatus: '',
                MonthList: '',
                spinning: false,
                startDate: '',
                endDate: '',
                Type:'',
                StudentId:'',
                dateFormat:"YYYY-MM-DD",
            }
        },
        props: ["LogsCalendarQueryData"],
        watch: {
            LogsCalendarQueryData:{
                immediate: true,
                handler (data) {
                    if (!data.StudentId) return;
                    this.StudentId = data.StudentId;
                    this.startDate = data.StartDate;
                    this.endDate = data.EndDate;
                    console.log(data)
                    this.loadData()
                },
                deep: true
            }
        },
        created() {
            let date = new Date()
            let time =
                date.getFullYear() +
                '-' +
                (date.getMonth() + 1 < 10
                    ? '0' + (date.getMonth() + 1)
                    : date.getMonth() + 1)
            this.dateValue = time
            console.log(this.dateValue)
            
            // this.loadMonth()
        },
        methods: {
            moment,
            loadData() {
                let self = this;
                self.list = '';
                this.$axios.post(
                    8000082,
                    {
                        StudentId: this.StudentId,
                        Type:self.Type,
                        StartDate:self.startDate,
                        EndDate:self.endDate,
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.list = res.data.data
                        } else {
                            // this.$message.error(res.data.message)
                        }
                    }
                )
            },

            getListData(value) {
                let date = new Date(value._d)
                let time =
                    date.getFullYear() +
                    '-' +
                    (date.getMonth() + 1 < 10
                        ? '0' + (date.getMonth() + 1)
                        : date.getMonth() + 1) +
                    '-' +
                    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())

                return this.list[time]
            },
            onPanelChange(value) {

                let date = new Date(value._d)
                let time =
                    date.getFullYear() +
                    '-' +
                    (date.getMonth() + 1 < 10
                        ? '0' + (date.getMonth() + 1)
                        : date.getMonth() + 1)
                this.dateValue = time
                this.TheTime = time
                
                let temp_d = moment(value._d.toLocaleDateString()).format(this.dateFormat);

                this.startDate = moment(temp_d).startOf('month').format(this.dateFormat);
                this.endDate = moment(temp_d).endOf('month').format(this.dateFormat);

                this.loadData()
            },
            totay() {
                let date = new Date()
                let time =
                    date.getFullYear() +
                    '-' +
                    (date.getMonth() + 1 < 10
                        ? '0' + (date.getMonth() + 1)
                        : date.getMonth() + 1)
                this.dateValue = time
                this.TheTime = time
                this.loadData()
            },
            //年月切换
            getMonthData(value) {
                let date = new Date(value)
                let time =
                    date.getFullYear() +
                    '-' +
                    (date.getMonth() + 1 < 10
                        ? '0' + (date.getMonth() + 1)
                        : date.getMonth() + 1)
                return this.MonthList[time]
                // if (value.month() === 8) {
                //     return 1394;
                // }
            },
            loadMonth() {
                let self = this
                this.$axios.post(
                    1050,
                    {
                        uid: self.userinfo.uid,
                        TheTime: self.TheTime,
                        Name: self.Name
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.MonthList = res.data.message
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            onSearch() {
                this.loadData()
            },
            moreData(userid, time) {
                ;(this.tableUserid = userid), (this.nowTime = time)
                this.tableFn(userid, time, '')
            },
            SearchData(num) {
                this.TableStatus = num
                this.tableFn(this.tableUserid, this.nowTime, num)
            },
            tableFn(userid, time, status) {
                this.spinning = true
                let self = this
                this.$axios.get(
                    1049,
                    {
                        uid: userid,
                        TheTime: time,
                        Status: status
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.errorCount = res.data.message.errorCount
                            self.successCount = res.data.message.successCount
                            self.Count = res.data.message.Count
                            self.Popupdata = res.data.message.data
                            self.spinning = false
                            self.visible = true
                        }
                    }
                )
            },
            StudentInfo(studentid, studentName) {
                let data = {
                    type: 'student',
                    name: studentName,
                    id: studentid
                }
                window.parent.postMessage(data, '*')
                //window.open("http://ims.qitianzhen.cn/index.php?m=Kms&c=Student&a=edit&UserId="+studentid,'target','');
            },
            onClose() {
                this.TableStatus = ''
                ;(this.tableUserid = ''), (this.nowTime = '')
                this.visible = false
            },
            headerRender({ value, type, onChange, onTypeChange }) {

            },
            _getDate(date){
                this.startDate = this.__moment__(date[0]).format('YYYY-MM-DD');
                this.endDate = this.__moment__(date[1]).format('YYYY-MM-DD');
                this.loadData();
            },
            _handleChange(value){
                this.Type = value;
                this.loadData();
            },
        }
    }
</script>

<style lang="less" scoped>

    

    * {
        font-family: Microsoft YaHei;
    }


    .ant-radio-group{
        display:none;
    }

    .LogsCalendar {
        padding: 20px;
        background: #ffffff;
    }

    .events {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .events > li {
        margin-bottom: 3px;
        border-radius: 3px;
        color:#fff;
        font-size: 10px;
        text-align: center;
    }

    .events .ant-badge-status {
        overflow-x: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        font-size: 12px;
    }

    .notes-month {
        text-align: center;
        font-size: 28px;
    }

    .record-title {
        font-size: 18px;
        color: rgba(102, 102, 102, 1);
    }

    .hook {
        color: #2994ffff;
    }

    .StudentName {
        color: #2994ffff;
    }

    .record-search {
        margin-right: 30px;
        margin-left: 30px;
        width: 200px;
        border-radius: 35px;
    }

    .record-tips {
        margin-right: 36px;
        color: rgba(102, 102, 102, 1);
        font-size: 12px;
        position: relative;
    }

    .tips-warning::after {
        content: '';
        width: 6px;
        height: 6px;
        background: #ff9c08;
        opacity: 1;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: -30%;
        transform: translateY(-50%);
    }

    .tips-error::after {
        content: '';
        width: 6px;
        height: 6px;
        background: #e0e1e2;
        opacity: 1;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: -30%;
        transform: translateY(-50%);
    }

    .tips-error2::after {
        content: '';
        width: 6px;
        height: 6px;
        background: #40a9ff;
        opacity: 1;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: -30%;
        transform: translateY(-50%);
    }

    .tips-error3::after {
        content: '';
        width: 6px;
        height: 6px;
        background: #096dd9;
        opacity: 1;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: -30%;
        transform: translateY(-50%);
    }

    .tips-success::after {
        content: '';
        width: 6px;
        height: 6px;
        background: #75c255;

        opacity: 1;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: -30%;
        transform: translateY(-50%);
    }

    /*.notes-month section {*/
    /*font-size: 28px;*/
    /*}*/
    /*.ant-fullcalendar-date{*/
    /*height: 190px !important;*/
    /*}*/
    /*.ant-fullcalendar-fullscreen .ant-fullcalendar-content{*/
    /*height: auto!important;*/
    /*}*/
    .ant-bth-color {
        color: rgb(41, 148, 255);
    }

    .success {
        //已跟进
        background: rgba(117, 194, 85, 1);
        border-radius: 4px;
        color: #ffffff;
        font-size: 12px;
        display: block;
        border-bottom: 1px solid #ffffff;
        overflow-x: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        text-align: left;
    }

    .warning {
        //已过期
        background: rgba(255, 156, 8, 1);
        border-radius: 4px;
        color: #ffffff;
        font-size: 12px;
        display: block;
        border-bottom: 1px solid #ffffff;
        overflow-x: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        text-align: left;
    }

    .error {
        //未跟进
        background: rgba(224, 225, 226, 1);
        border-radius: 4px;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
        display: block;
        border-bottom: 1px solid #ffffff;
        overflow-x: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        text-align: left;
    }

    .more {
        font-size: 12px;
        color: rgba(36, 36, 36, 1);
        text-align: left;
        display: block;
        width: 100%;
        position: absolute;
        bottom: 10px;
        .more-tipe {
            color: #ff0000ff;
        }
    }
</style>
